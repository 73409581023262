// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ScheduleCounter-module__Zx_9FDSBeAGWZjxumFDu{font-size:12px;line-height:16px;padding:3px 4px}.ScheduleCounter-module__JHmx596dVTaVmzsaf3qa{background:rgba(27,133,94,.15);border:1px solid var(--tag-border-success);border-radius:2px}.ScheduleCounter-module__OCo9P33wUVKgJIdKwfCE{background:rgba(245,183,61,.18);border:1px solid var(--tag-border-warning);border-radius:2px}.ScheduleCounter-module__X_mzTDRr2d2fijmRt3wX{padding:3px 10px}.ScheduleCounter-module__mgA4sntbYrGudmM0iONx,.ScheduleCounter-module__fu_X9cybevnf_3WN84W5{color:var(--tag-text-success)}.ScheduleCounter-module__UMFm_3muHZhLnrkTobFT,.ScheduleCounter-module__7yN71C63VEwoxxVNxy_A{color:var(--tag-text-warning)}.ScheduleCounter-module__NuXjx89EVMaLoihvhzgn{width:auto}", "",{"version":3,"sources":["webpack://./components/ScheduleCounter/ScheduleCounter.module.scss"],"names":[],"mappings":"AAAA,8CACE,cAAA,CACA,gBAAA,CACA,eAAA,CAEA,8CACE,8BAAA,CACA,0CAAA,CACA,iBAAA,CAGF,8CACE,+BAAA,CACA,0CAAA,CACA,iBAAA,CAGF,8CACE,gBAAA,CAIJ,4FAEE,6BAAA,CAGF,4FAEE,6BAAA,CAGF,8CACE,UAAA","sourcesContent":[".element {\n  font-size: 12px;\n  line-height: 16px;\n  padding: 3px 4px;\n\n  &--link {\n    background: rgba(27, 133, 94, 0.15);\n    border: 1px solid var(--tag-border-success);\n    border-radius: 2px;\n  }\n\n  &--warning {\n    background: rgba(245, 183, 61, 0.18);\n    border: 1px solid var(--tag-border-warning);\n    border-radius: 2px;\n  }\n\n  &--padding {\n    padding: 3px 10px;\n  }\n}\n\n.element__text--link,\n.element__icon--link {\n  color: var(--tag-text-success);\n}\n\n.element__text--warning,\n.element__icon--warning {\n  color: var(--tag-text-warning);\n}\n\n.tooltip {\n  width: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"element": "ScheduleCounter-module__Zx_9FDSBeAGWZjxumFDu",
	"element--link": "ScheduleCounter-module__JHmx596dVTaVmzsaf3qa",
	"element--warning": "ScheduleCounter-module__OCo9P33wUVKgJIdKwfCE",
	"element--padding": "ScheduleCounter-module__X_mzTDRr2d2fijmRt3wX",
	"element__text--link": "ScheduleCounter-module__mgA4sntbYrGudmM0iONx",
	"element__icon--link": "ScheduleCounter-module__fu_X9cybevnf_3WN84W5",
	"element__text--warning": "ScheduleCounter-module__UMFm_3muHZhLnrkTobFT",
	"element__icon--warning": "ScheduleCounter-module__7yN71C63VEwoxxVNxy_A",
	"tooltip": "ScheduleCounter-module__NuXjx89EVMaLoihvhzgn"
};
export default ___CSS_LOADER_EXPORT___;
