// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EscalationsFilters-module__L7axK3vCsZFk78gN5VKJ {\n  display: flex;\n  align-items: center;\n}\n\n.EscalationsFilters-module__zBCaZjES4hHTJ5ONtbpg {\n  max-width: 400px;\n}\n\n.EscalationsFilters-module__XskYzT2tPAsptB8UmIul {\n  color: var(--secondary-text-color);\n  margin-left: 8px;\n}\n", "",{"version":3,"sources":["webpack://./components/EscalationsFilters/EscalationsFilters.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kCAAkC;EAClC,gBAAgB;AAClB","sourcesContent":[".root {\n  display: flex;\n  align-items: center;\n}\n\n.search {\n  max-width: 400px;\n}\n\n.icon-button {\n  color: var(--secondary-text-color);\n  margin-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "EscalationsFilters-module__L7axK3vCsZFk78gN5VKJ",
	"search": "EscalationsFilters-module__zBCaZjES4hHTJ5ONtbpg",
	"icon-button": "EscalationsFilters-module__XskYzT2tPAsptB8UmIul"
};
export default ___CSS_LOADER_EXPORT___;
