// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GrafanaTeamSelect-module__pDwfaLISbqVLqW5r7Tcj{width:200px}.GrafanaTeamSelect-module__JVj4tuphCIWyMbXVcwaQ{display:flex}.GrafanaTeamSelect-module__mtIet88nnJpH368ykhfi,.GrafanaTeamSelect-module__yDq7jAfMfiTqFjY8cdzl{line-height:1.25;margin-bottom:4px}.GrafanaTeamSelect-module__yDq7jAfMfiTqFjY8cdzl{margin-left:auto;color:var(--primary-text-link)}.GrafanaTeamSelect-module__TRUeiqMdxOzCuRLnQict{margin-left:4px}", "",{"version":3,"sources":["webpack://./containers/GrafanaTeamSelect/GrafanaTeamSelect.module.scss"],"names":[],"mappings":"AAAA,gDACE,WAAA,CAGF,gDACE,YAAA,CAGF,gGAEE,gBAAA,CACA,iBAAA,CAGF,gDACE,gBAAA,CACA,8BAAA,CAGF,gDACE,eAAA","sourcesContent":[".teamSelect {\n  width: 200px;\n}\n\n.teamSelectLabel {\n  display: flex;\n}\n\n.teamSelectText,\n.teamSelectLink {\n  line-height: 1.25;\n  margin-bottom: 4px;\n}\n\n.teamSelectLink {\n  margin-left: auto;\n  color: var(--primary-text-link);\n}\n\n.teamSelectInfo {\n  margin-left: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"teamSelect": "GrafanaTeamSelect-module__pDwfaLISbqVLqW5r7Tcj",
	"teamSelectLabel": "GrafanaTeamSelect-module__JVj4tuphCIWyMbXVcwaQ",
	"teamSelectText": "GrafanaTeamSelect-module__mtIet88nnJpH368ykhfi",
	"teamSelectLink": "GrafanaTeamSelect-module__yDq7jAfMfiTqFjY8cdzl",
	"teamSelectInfo": "GrafanaTeamSelect-module__TRUeiqMdxOzCuRLnQict"
};
export default ___CSS_LOADER_EXPORT___;
