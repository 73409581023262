// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Incidents-module__VasMyrwDRd2IlgCEHO1S{width:400px}.Incidents-module__eOFqo3gBNVeZqO4TPO0s{width:100%;justify-content:flex-end}.Incidents-module__nmawGpJ4qsieGFzByCvE{margin-bottom:20px}.Incidents-module__gJb4m2tDLK7QnSSPclIB{display:flex;justify-content:space-between;align-items:center}.Incidents-module__IPM2LkRxTNpjRs8V4xdW{margin:10px 0 10px 0}.Incidents-module__LxuwJD4QQaJ7_I4_I0GA{color:var(--secondary-text-color)}.Incidents-module__A3Shxd2kBXJsY8n21E4X{font-size:12px;height:24px;margin-right:0}.Incidents-module__Kcx1nozB38Ya3OHye9HQ{width:100%;margin-top:20px}.Incidents-module__wH6zd7FkliwmRQEd_dnQ{margin-bottom:24px;right:0}", "",{"version":3,"sources":["webpack://./pages/incidents/Incidents.module.scss"],"names":[],"mappings":"AAAA,wCACE,WAAA,CAGF,wCACE,UAAA,CACA,wBAAA,CAGF,wCACE,kBAAA,CAGF,wCACE,YAAA,CACA,6BAAA,CACA,kBAAA,CAGF,wCACE,oBAAA,CAGF,wCACE,iCAAA,CAGF,wCACE,cAAA,CACA,WAAA,CACA,cAAA,CAGF,wCACE,UAAA,CACA,eAAA,CAGF,wCACE,kBAAA,CACA,OAAA","sourcesContent":[".select {\n  width: 400px;\n}\n\n.action-buttons {\n  width: 100%;\n  justify-content: flex-end;\n}\n\n.filters {\n  margin-bottom: 20px;\n}\n\n.above-incidents-table {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.bulk-actions {\n  margin: 10px 0 10px 0;\n}\n\n.other-users {\n  color: var(--secondary-text-color);\n}\n\n.silence-button-inline {\n  font-size: 12px;\n  height: 24px;\n  margin-right: 0;\n}\n\n.pagination {\n  width: 100%;\n  margin-top: 20px;\n}\n\n.title {\n  margin-bottom: 24px;\n  right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": "Incidents-module__VasMyrwDRd2IlgCEHO1S",
	"action-buttons": "Incidents-module__eOFqo3gBNVeZqO4TPO0s",
	"filters": "Incidents-module__nmawGpJ4qsieGFzByCvE",
	"above-incidents-table": "Incidents-module__gJb4m2tDLK7QnSSPclIB",
	"bulk-actions": "Incidents-module__IPM2LkRxTNpjRs8V4xdW",
	"other-users": "Incidents-module__LxuwJD4QQaJ7_I4_I0GA",
	"silence-button-inline": "Incidents-module__A3Shxd2kBXJsY8n21E4X",
	"pagination": "Incidents-module__Kcx1nozB38Ya3OHye9HQ",
	"title": "Incidents-module__wH6zd7FkliwmRQEd_dnQ"
};
export default ___CSS_LOADER_EXPORT___;
