// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SchedulesFilters-module__ZPwvbwK5tT0KTtylH59_{display:flex;flex-wrap:wrap;row-gap:4px;column-gap:8px}@media screen and (max-width: 1600px){.SchedulesFilters-module__ZPwvbwK5tT0KTtylH59_{order:3;width:100%}}", "",{"version":3,"sources":["webpack://./components/SchedulesFilters/SchedulesFilters.module.scss"],"names":[],"mappings":"AAAA,+CACE,YAAA,CACA,cAAA,CACA,WAAA,CACA,cAAA,CAGF,sCACE,+CACE,OAAA,CACA,UAAA,CAAA","sourcesContent":[".right {\n  display: flex;\n  flex-wrap: wrap;\n  row-gap: 4px;\n  column-gap: 8px;\n}\n\n@media screen and (max-width: 1600px) {\n  .right {\n    order: 3;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"right": "SchedulesFilters-module__ZPwvbwK5tT0KTtylH59_"
};
export default ___CSS_LOADER_EXPORT___;
