// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AlertReceiveChannelCard-module__t0KsITWqZqpPcOa_SoYP {\n  display: block;\n}\n\n.AlertReceiveChannelCard-module__hszB2fApaHrCmUwRpdwn {\n  width: 16px;\n  height: 16px;\n}\n\n.AlertReceiveChannelCard-module__xihXP7uUqUAZ6oOmnCEi {\n  cursor: pointer;\n}\n\n.AlertReceiveChannelCard-module__GngvI7W5sxN9y78HTAEZ {\n  font-size: 12px;\n}\n", "",{"version":3,"sources":["webpack://./containers/AlertReceiveChannelCard/AlertReceiveChannelCard.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".root {\n  display: block;\n}\n\n.heartbeat {\n  width: 16px;\n  height: 16px;\n}\n\n.heartbeat-icon {\n  cursor: pointer;\n}\n\n.alertsInfoText {\n  font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "AlertReceiveChannelCard-module__t0KsITWqZqpPcOa_SoYP",
	"heartbeat": "AlertReceiveChannelCard-module__hszB2fApaHrCmUwRpdwn",
	"heartbeat-icon": "AlertReceiveChannelCard-module__xihXP7uUqUAZ6oOmnCEi",
	"alertsInfoText": "AlertReceiveChannelCard-module__GngvI7W5sxN9y78HTAEZ"
};
export default ___CSS_LOADER_EXPORT___;
