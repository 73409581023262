// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Autoresolve-module__JwqY4Tktg5R7F5iAhoQx {\n  margin-bottom: 32px;\n  padding-bottom: 32px;\n  border-bottom: var(--border);\n}\n\n.Autoresolve-module__jis25HwtbwzO8Lf40AYT ul {\n  margin-left: 24px;\n}\n\n.Autoresolve-module__kMpQtGrr7kMUfXD7pAyA {\n  display: grid;\n}\n\n.Autoresolve-module__LaILog6yJ1NQ1RkRkmEf {\n  width: 520px;\n}\n\n.Autoresolve-module__MYNYiDxGbAbhsNjiE4sl {\n  margin-top: 24px;\n}\n\n.Autoresolve-module__r7eOu03B9VGYY4Euq63C {\n  display: flex;\n  flex-direction: row-reverse;\n}\n\n.Autoresolve-module__r7eOu03B9VGYY4Euq63C .Autoresolve-module__XD7twnu10uEkRbJd5Lbx {\n  margin-left: 8px;\n}\n\n.Autoresolve-module__k2G3p6vubiqyQw5qqUhn {\n  height: 32px;\n  padding: 4px 8px;\n  margin-top: 8px;\n  min-width: 500px;\n  width: 520px;\n}\n\n.Autoresolve-module__WvgzFVPE13nvx0BSac4u {\n  color: var(--warning-text-color);\n}\n", "",{"version":3,"sources":["webpack://./containers/IntegrationSettings/parts/Autoresolve.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,oBAAoB;EACpB,4BAA4B;AAC9B;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,2BAA2B;AAC7B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,gCAAgC;AAClC","sourcesContent":[".border-container {\n  margin-bottom: 32px;\n  padding-bottom: 32px;\n  border-bottom: var(--border);\n}\n\n.root ul {\n  margin-left: 24px;\n}\n\n.settings-label {\n  display: grid;\n}\n\n.team-select {\n  width: 520px;\n}\n\n.team-select-actionbuttons {\n  margin-top: 24px;\n}\n\n.confirmation-buttons {\n  display: flex;\n  flex-direction: row-reverse;\n}\n\n.confirmation-buttons .save-team-button {\n  margin-left: 8px;\n}\n\n.autoresolve-block {\n  height: 32px;\n  padding: 4px 8px;\n  margin-top: 8px;\n  min-width: 500px;\n  width: 520px;\n}\n\n.warning-icon-color {\n  color: var(--warning-text-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"border-container": "Autoresolve-module__JwqY4Tktg5R7F5iAhoQx",
	"root": "Autoresolve-module__jis25HwtbwzO8Lf40AYT",
	"settings-label": "Autoresolve-module__kMpQtGrr7kMUfXD7pAyA",
	"team-select": "Autoresolve-module__LaILog6yJ1NQ1RkRkmEf",
	"team-select-actionbuttons": "Autoresolve-module__MYNYiDxGbAbhsNjiE4sl",
	"confirmation-buttons": "Autoresolve-module__r7eOu03B9VGYY4Euq63C",
	"save-team-button": "Autoresolve-module__XD7twnu10uEkRbJd5Lbx",
	"autoresolve-block": "Autoresolve-module__k2G3p6vubiqyQw5qqUhn",
	"warning-icon-color": "Autoresolve-module__WvgzFVPE13nvx0BSac4u"
};
export default ___CSS_LOADER_EXPORT___;
