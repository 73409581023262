// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OutgoingWebhooks-module__fAcfODf2ZTs1IdhVw5sv {\n  display: flex;\n  align-items: center;\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./pages/outgoing_webhooks/OutgoingWebhooks.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;AACb","sourcesContent":[".header {\n  display: flex;\n  align-items: center;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "OutgoingWebhooks-module__fAcfODf2ZTs1IdhVw5sv"
};
export default ___CSS_LOADER_EXPORT___;
