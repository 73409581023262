// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Header-module__lQwQGT27k9kLe7yu1a0E{margin-right:4px}.Header-module__IIJ1mHjWBRPZUY5m9LUj{padding-top:0;padding-bottom:36px}.Header-module__iaCYjnEoGDviCg6kFCmZ{padding:4px;border:1px solid var(--gray-9);width:initial;font-size:12px;padding-top:0;margin-bottom:0}.Header-module__wgLyvbF59pUzbXQioTFS{display:flex;align-items:center;padding-top:6px}.Header-module__km70DNwrey7OlvGKzfHX{display:flex;flex-basis:100%}.Header-module__ddniq4LoygvZfhFWIZlc{display:flex;flex-wrap:wrap;align-items:center;flex-direction:row;column-gap:8px;row-gap:8px}", "",{"version":3,"sources":["webpack://./navbar/Header/Header.module.scss"],"names":[],"mappings":"AAAA,qCACE,gBAAA,CAGF,qCACE,aAAA,CACA,mBAAA,CAGF,qCACE,WAAA,CACA,8BAAA,CACA,aAAA,CACA,cAAA,CACA,aAAA,CACA,eAAA,CAGF,qCACE,YAAA,CACA,kBAAA,CACA,eAAA,CAGF,qCACE,YAAA,CACA,eAAA,CAGF,qCACE,YAAA,CACA,cAAA,CACA,kBAAA,CACA,kBAAA,CACA,cAAA,CACA,WAAA","sourcesContent":[".navbar-star-icon {\n  margin-right: 4px;\n}\n\n.header-topnavbar {\n  padding-top: 0;\n  padding-bottom: 36px;\n}\n\n.navbar-heading {\n  padding: 4px;\n  border: 1px solid var(--gray-9);\n  width: initial;\n  font-size: 12px;\n  padding-top: 0;\n  margin-bottom: 0;\n}\n\n.navbar-link {\n  display: flex;\n  align-items: center;\n  padding-top: 6px;\n}\n\n.navbar-left {\n  display: flex;\n  flex-basis: 100%;\n}\n\n.navbar-heading-container {\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  flex-direction: row;\n  column-gap: 8px;\n  row-gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navbar-star-icon": "Header-module__lQwQGT27k9kLe7yu1a0E",
	"header-topnavbar": "Header-module__IIJ1mHjWBRPZUY5m9LUj",
	"navbar-heading": "Header-module__iaCYjnEoGDviCg6kFCmZ",
	"navbar-link": "Header-module__wgLyvbF59pUzbXQioTFS",
	"navbar-left": "Header-module__km70DNwrey7OlvGKzfHX",
	"navbar-heading-container": "Header-module__ddniq4LoygvZfhFWIZlc"
};
export default ___CSS_LOADER_EXPORT___;
