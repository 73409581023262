// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IntegrationsFilters-module__i0dLKcV9viRSjmK5d_uA {\n  display: block;\n}\n\n.IntegrationsFilters-module__zRawJ_ok01yINe19cu34 {\n  display: flex;\n  position: relative;\n}\n\n.IntegrationsFilters-module__fRbzDukluZuqz81MrTwh {\n  position: absolute;\n  right: 0;\n}\n\n.IntegrationsFilters-module__dtpiNUspaA846J_SgsWQ {\n  max-width: 400px;\n}\n", "",{"version":3,"sources":["webpack://./components/IntegrationsFilters/IntegrationsFilters.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;AACV;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".root {\n  display: block;\n}\n\n.integrationsFilters {\n  display: flex;\n  position: relative;\n}\n\n.searchIntegrationClear {\n  position: absolute;\n  right: 0;\n}\n\n.searchIntegrationInput {\n  max-width: 400px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "IntegrationsFilters-module__i0dLKcV9viRSjmK5d_uA",
	"integrationsFilters": "IntegrationsFilters-module__zRawJ_ok01yINe19cu34",
	"searchIntegrationClear": "IntegrationsFilters-module__fRbzDukluZuqz81MrTwh",
	"searchIntegrationInput": "IntegrationsFilters-module__dtpiNUspaA846J_SgsWQ"
};
export default ___CSS_LOADER_EXPORT___;
