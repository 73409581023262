// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EscalationChainCard-module__wcTQFUmcRkI_9qGyU7ON {\n  display: block;\n}\n\n.EscalationChainCard-module__JYMpUVMcybebz78U1V8v {\n  padding: 2px 4px;\n  background: rgba(27, 133, 94, 0.15);\n  border: 1px solid var(--success-text-color);\n  border-radius: 2px;\n}\n\n.EscalationChainCard-module__X0prPR38BWxTj0waD2NA {\n  color: var(--success-text-color);\n}\n", "",{"version":3,"sources":["webpack://./containers/EscalationChainCard/EscalationChainCard.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,mCAAmC;EACnC,2CAA2C;EAC3C,kBAAkB;AACpB;;AAEA;EACE,gCAAgC;AAClC","sourcesContent":[".root {\n  display: block;\n}\n\n.connected-integrations {\n  padding: 2px 4px;\n  background: rgba(27, 133, 94, 0.15);\n  border: 1px solid var(--success-text-color);\n  border-radius: 2px;\n}\n\n.icon {\n  color: var(--success-text-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "EscalationChainCard-module__wcTQFUmcRkI_9qGyU7ON",
	"connected-integrations": "EscalationChainCard-module__JYMpUVMcybebz78U1V8v",
	"icon": "EscalationChainCard-module__X0prPR38BWxTj0waD2NA"
};
export default ___CSS_LOADER_EXPORT___;
